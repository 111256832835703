import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['ButtonDialog_expectationDialog'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Typography = makeShortcode("Typography");
const Grid = makeShortcode("Grid");
const SaveTextField = makeShortcode("SaveTextField");
const ButtonDialog = makeShortcode("ButtonDialog");
const GoTo = makeShortcode("GoTo");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "managing-your-expectations"
    }}>{`Managing your expectations`}</h2>
    <p>{`As a parent, you probably already have several expectations for your teen. For example, expectations about how they behave, how they treat others, their education and activities, and helping out at home. Parental expectations are important for your teen’s development `}{`—`}{` they provide clear boundaries and can help your teen feel safe and secure.`}</p>
    <p>{`With the changes and uncertainty brought about by the pandemic, you may need to adjust some of your expectations. This includes your expectations of your teen, and also of yourself.`}</p>
    <Typography color="primary" mdxType="Typography">
  Think about the expectations you have of your teen right now. Have any of your expectations not been met because of
  the pandemic?
    </Typography>
    <p>{`Consider:`}</p>
    <ul>
      <li parentName="ul">{`Your teen’s behaviour or emotions`}</li>
      <li parentName="ul">{`Your teen’s productivity or schoolwork`}</li>
      <li parentName="ul">{`Your teen’s contributions at home`}</li>
    </ul>
    <Grid container justify="center" spacing={2} mdxType="Grid">
  <SaveTextField id="pandemicExpectations" variant="outlined" fullWidth={true} multiline={true} label="Insert text here" mdxType="SaveTextField" />
    </Grid>
    <p>{`Now think about expectations you have of yourself as a parent. Are you finding it hard to meet these expectations of yourself
during the pandemic?`}</p>
    <ButtonDialog linkText="Tips to Consider" title="Tips to Consider:" id="expectationDialog" mdxType="ButtonDialog">
  <ul>
    <li>
      Adjust your expectations to be realistic based on the circumstances. This may mean changing expectations
      throughout the pandemic.
    </li>
    <li>Focus on your teen’s emotional and physical wellbeing, rather than on perfection or high productivity.</li>
    <li>Try to prioritise the most important tasks for your teen each day (e.g. sleep, nutrition, exercise).</li>
    <li>
      Practice self-compassion and forgiveness &mdash; be kind to your teen, and to yourself, if either of you don’t
      meet your expectations.
    </li>
  </ul>
    </ButtonDialog>
    <p><strong parentName="p">{`Speak with your teen about your expectations.`}</strong>{` If you haven’t spoken with your teen about expectations lately, have a chat with them. Clearly explain your current expectations, including any adjustments you think are needed during the pandemic. Involve your teen as much as possible in this conversation `}{`—`}{` the more you involve them, the more likely they are to be on board with the expectations!`}</p>
    <p>{`For more information about family rules and expectations, see the module `}<GoTo to="/dashboard" mdxType="GoTo"><em parentName="p">{`Raising good kids into great adults: Establishing family rules`}</em></GoTo>{`.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      